const Button = ({ buttonName, buttonType, onClick, fullWidth }) => {
  return (
    <div className="">
      <button
        className={`bg-blue-500 rounded-[5px] py-3 px-[30px] text-white text-sm font-normal ${
          fullWidth ? "w-full" : "" // Conditionally add the 'w-full' class
        } text-center transform transition-transform duration-300 hover:scale-105`}
        type={buttonType}
        onClick={onClick}
      >
        {buttonName}
      </button>
    </div>
  );
};

export default Button;
