import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

// Async thunk to fetch merchants
export const fetchMerchants = createAsyncThunk(
  "merchants/fetchMerchants",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/all_merchants"); // Adjust the URL to your API endpoint
      // console.log(response);
      return response.data.sellers;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to activate a merchant
export const activateMerchant = createAsyncThunk(
  "merchants/activateMerchant",
  async (merchantId, { rejectWithValue }) => {
    try {
      const response = await api.post(`/activate_merchant/${merchantId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to deactivate a merchant
export const deactivateMerchant = createAsyncThunk(
  "merchants/deactivateMerchant",
  async (merchantId, { rejectWithValue }) => {
    try {
      const response = await api.post(`/deactivate_merchant/${merchantId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const merchantReducer = createSlice({
  name: "allmerchants",
  initialState: {
    merchants: [],
    status: "idle",
    success: null,
    error: null,
  },
  reducers: {
    messageClear: (state) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchants.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMerchants.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.merchants = action.payload;
      })
      .addCase(fetchMerchants.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
      })
      // .addCase(activateMerchant.fulfilled, (state, action) => {
      //   const updatedMerchant = action.payload;
      //   state.merchants = state.merchants.map((merchant) =>
      //     merchant.merchant_id === updatedMerchant.merchant_id
      //       ? updatedMerchant
      //       : merchant
      //   );
      // })
      // .addCase(deactivateMerchant.fulfilled, (state, action) => {
      //   const updatedMerchant = action.payload;
      //   state.merchants = state.merchants.map((merchant) =>
      //     merchant.merchant_id === updatedMerchant.merchant_id
      //       ? updatedMerchant
      //       : merchant
      //   );
      // });
      .addCase(activateMerchant.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(activateMerchant.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deactivateMerchant.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(deactivateMerchant.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// export default merchantSlice.reducer;
export const { messageClear } = merchantReducer.actions;
export default merchantReducer.reducer;
