// import { configureStore } from "@reduxjs/toolkit";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import rootReducer from "./rootReducer";

// // Configure persist
// const persistConfig = {
//   key: "root",
//   storage,
//   whitelist: ["auth", "register"], // Only persist the auth reducer
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   // reducer: rootReducer,
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) => {
//     return getDefaultMiddleware({
//       serializableCheck: false,
//     });
//   },

//   devTools: true,
// });

// const persistor = persistStore(store);

// export { store, persistor };

import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";

// Configure store without persist
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
  devTools: true,
});

export { store };
